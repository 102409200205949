import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const Label = ({ text, type }) => (
  <div className={`${type === 'enterprise' ? 'pastel-blue' : 'pastel-red'}`}>{text}</div>
)
const Content = ({ content }) => <div>{content}</div>
const ReadMore = ({ href }) => (
  <a
    className="underline pointer link f7"
    style={{ textDecoration: 'underline', textUnderlinePosition: 'under' }}
    href={href}
  >
    Read More &#8599;
  </a>
)

const caseStudyList = () => {
  const data = useStaticQuery(graphql`
    query CaseStudyQuery {
      allCaseStudyJson {
        nodes {
          label
          src
          type
          link
          content
        }
      }
    }
  `)
  return data.allCaseStudyJson.nodes
}

export const CaseStudy = ({ type }) => {
  const filteredCaseStudy = caseStudyList().filter(
    caseStudy => caseStudy.type === type && caseStudy.link
  )

  return (
    <div className="pv4 pv5-ns mh0-ns">
      <div className="mono">CASE STUDIES</div>
      <div className="mw7-m mw8-l mv3 image-grid-large">
        {filteredCaseStudy.map((caseStudy, i) => (
          <div key={i} className="pr4-ns mb4">
            {/* <Image src={caseStudy.src} /> */}
            <Label text={caseStudy.label} type={caseStudy.type} />
            <Content content={caseStudy.content} />
            <ReadMore href={caseStudy.link} />
          </div>
        ))}
      </div>
    </div>
  )
}
