import React from 'react'
import Layout from '../components/layout'
import { WhatWeDo, Clients } from '../components/VerticalComponents'
import { StickyScrollerDataVizArt } from '../components/StickyScrollerDataVizArt'
import { CaseStudy } from '../components/shared/CaseStudy'
import { FollowUsModule } from '../components/FollowUsModule'
import heroDesktop from '../../static/images/data-driven-art/hero-dataart.jpg'
import heroMobile from '../../static/images/data-driven-art/hero-dataart-mobile.jpg'

export default class DataDrivenArtDesignPage extends React.Component {
  render() {
    const { location } = this.props

    return (
      <Layout
        type="home"
        location={location}
        title="Data Art"
        description="Data―driven Art + Design"
      >
        <div className="flex flex-column mw7-m mw8-l center-m center-l ph3">
          <div
            className="mv4-ns h6 dn-s bg-fit"
            style={{
              backgroundImage: `url(${heroDesktop})`,
            }}
          />
          <div
            className="dn-ns bg-fit"
            style={{
              backgroundImage: `url(${heroMobile})`,
              height: 260,
            }}
          />
          <WhatWeDo />
          <StickyScrollerDataVizArt />
          <Clients logos={logos} isBorderBottom />
          <CaseStudy type={'datadriven'} />
        </div>
        <FollowUsModule />
      </Layout>
    )
  }
}

const logos = [
  // 'corriere-della-sera',
  'columbia-university',
  'google',
  'gucci',
  'ibm',
  'lvmh',
  'moma',
  'starbucks',
  'ted',
  'the-new-york-times',
  'united-nations-foundation',
  'world-economic-forum',
  'world-health-organization',
]
